#cards {
  align-items: center;
  background-color: var(--bg-color);
  display: flex;
  /* height: 100vh; */
  justify-content: center;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  flex-wrap: wrap;
  gap: 8px;
  /* max-width: 916px; */
  width: calc(100% - 20px);
}

#cards:hover > .card::after {
  opacity: 1;
}
